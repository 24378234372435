<template>
	<NuxtImg
		v-bind="$attrs"
		:src="dynamicImage"
		:class="[imageWidthClass, imageHeightClass]"
		:alt="dynamicAltText"
		:style="styleStr"
		:loading="loading"
	/>
</template>

<script setup>
import { computed } from 'vue'

const runtimeConfig = useRuntimeConfig()

const props = defineProps({
	image: {
		type: String,
		required: true,
	},
	altText: {
		type: String,
		default: '',
		required: false,
	},
	styleStr: {
		type: String,
		default: '',
		required: false,
	},
	imageWidthClass: {
		type: String,
		default: 'w-full',
		required: false,
	},
	imageHeightClass: {
		type: String,
		default: 'h-auto',
		required: false,
	},
	loading: {
		type: String,
		default: 'lazy',
		required: false,
		validator: (val) => ['lazy', 'eager'].includes(val),
	},
})

const dynamicAltText = computed(() => {
	if (props.altText) {
		return props.altText
	}

	// return just the filename
	return props.image.split('/').pop()
})

const dynamicImage = computed(() => {
	if (props.image.includes(runtimeConfig.public.IMAGE_URL)) {
		return props.image
	}

	// to avoid any issues with SEO conflicts & trailing slashes, images MUST be in the public/img folder
	// this means we have to ensure usages of CarbarImage are provided with a prefix of img
	// we can't use this dynamicImage const to prefix them for us as the build process ignores this during compilation
	if (!props.image.startsWith('img')) {
		throw new Error(
			'image must have img prefix and reside in the public/img folder'
		)
	}

	return props.image
})
</script>
